import { Action, handleActions, ReducerMap } from 'redux-actions';
import { parseMaplegendToSettings, layerListToRecord } from 'utils/map';
import { StorageKeys, initLayers } from 'constants/index';
import { getStorageItem, setStorageItem } from 'utils/storage';
import { filterMapState, mergeMapState } from 'utils/map';
import { _cloneDeep } from '@utiligize/shared/utils';
import {
  setMapLoading,
  fetchConfigAction,
  mapStateAction,
  fetchMaxLoadSummaryAction,
  fetchTasksFeatureCollection,
  fetchTaskFiltersAction,
  setSettingsAction,
  setMapLayersAction,
  setDataQualityWarningAction,
  fetchNearestAssetLocationAction,
  fetchPopupAssetInfoAction,
  fetchN1RouteAction,
  setN1RouteAction,
  setSelectedN1RoutesAction,
  setSelectedN1RouteIdAction,
  setAddressSearchAction,
  fetchDataQualityWarningAction,
  fetchInvestmentScenariosAction,
  setSelectedInvestmentScenarioAction,
  setCableAnimationAction,
  setLabelsAction,
  setConfigFetchedAction,
  fetchSingleLineDiagramAction,
  setMapDrawAssetFeaturesAction,
  updateMapDrawAssetFeaturePropertiesAction,
  changeFilterVoltagesAction,
} from './actions';

const initialState: Map.Root = {
  loading: false,
  legendData: {},
  mapState: {} as Map.MapState,
  tasksFeatureCollection: null,
  taskFilters: null,
  settings: {
    assetLayers: [],
    cnaimLayers: [],
    otherLayers: [],
    dataQualityLayers: [],
    maxLoadLayers: [],
    maxLoadIconsLayers: [],
    n1Layers: [],
    n1MaxLoadLayers: [],
    lossesLayers: [],
    consumptionLayers: [],
    taskLayers: [],
    replacementsLayers: [],
    satelliteLayers: [],
    infoLayers: [],
    highlightLayers: [],
    themeActiveFilters: {},
  },
  dataQualityWarning: null,
  n1Route: null,
  selectedN1Routes: null,
  selectedN1RouteId: null,
  showAddressSearch: false,
  investmentScenarios: null,
  selectedInvestmentScenario: null,
  showCableAnimation: false,
  showLabels: true,
  isConfigFetched: false,
  mapDrawAssetFeatures: getStorageItem(StorageKeys.MAP_DRAWN_FEATURES) || null,
};

export const mapDefaultState: Map.MapInitialState<Partial<Map.MapState>> = {
  zoom: () => 8,
  currentBounds: () => null,
  theme: () => null,
  themeGroup: () => null,
  satelliteVisibility: () => false,
  buildingsVisibility: () => false,
  showMaxLoadVoltage: () => false,
  enabledLayers: state =>
    layerListToRecord(
      state.settings.assetLayers!.filter(l => (state.settings.initLayers ?? initLayers).some(x => l.startsWith(x))),
      true
    ),
  layerFilters: () => ({}),
  globalFilters: state => {
    const filters = state.settings.globalFilters;
    if (!filters) return {};
    return Object.keys(filters).reduce(
      (acc, key) => {
        const list = filters[key].list.filter(i => i.default_state ?? true).map(i => i.id);
        acc[key] = { ...filters[key], list, initList: filters[key].list };
        return acc;
      },
      {} as Record<string, any>
    );
  },
  dataQualityFilters: state => {
    const f = state.settings.dataQualityFilters;
    if (!f) return null;
    return { property: f.property!, list: f.list.map(i => i.id), initList: f.list };
  },
  replacementsFilters: state => {
    const f = state.settings.replacementsFilters;
    if (!f) return null;
    return { property: f.property!, list: f.list.map(i => i.id), initList: f.list };
  },
  taskDepartments: () => null,
  taskActions: () => null,
  taskCategories: () => null,
  taskTypes: () => null,
  taskUserEmails: () => null,
  taskDateRange: () => ({ startYear: new Date().getFullYear(), endYear: new Date().getFullYear() }),
  collapseGroupState: () => ({}),
  showConsumptionPercents: () => false,
  showLossesPercents: () => false,
  n1MaxLoadValueType: () => 'percent',
  assetSearchWithGeometry: state => !state.settings.isAssetGroupDisabled,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: ReducerMap<Map.Root, any> = {
  [setMapLoading.toString()]: (state: Map.Root, action: Action<Map.Root['loading']>): Map.Root => ({
    ...state,
    loading: action.payload,
  }),

  [setConfigFetchedAction.toString()]: {
    next: (state: Map.Root, action: Action<boolean>): Map.Root => ({
      ...state,
      isConfigFetched: action.payload,
    }),
  },

  [fetchConfigAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.LegendData>): Map.Root => ({
      ...state,
      legendData: action.payload,
      settings: { ...state.settings, ...parseMaplegendToSettings(action.payload) },
    }),
  },

  [setAddressSearchAction.toString()]: (state: Map.Root, action: Action<Map.Root['showAddressSearch']>): Map.Root => ({
    ...state,
    showAddressSearch: action.payload,
  }),

  [setCableAnimationAction.toString()]: (
    state: Map.Root,
    action: Action<Pick<Map.Root, 'showCableAnimation'>>
  ): Map.Root => ({
    ...state,
    showCableAnimation: action.payload.showCableAnimation,
  }),

  [setLabelsAction.toString()]: (state: Map.Root, action: Action<Map.Root['showLabels']>): Map.Root => ({
    ...state,
    showLabels: action.payload,
  }),

  [mapStateAction.toString()]: (state: Map.Root, action: Action<Map.MapState>): Map.Root => {
    setStorageItem({ [StorageKeys.MAP_CACHE]: filterMapState(_cloneDeep(action.payload)) });
    return {
      ...state,
      mapState: action.payload,
    };
  },

  [fetchTasksFeatureCollection.toString()]: {
    next: (state: Map.Root, action: Action<Map.Root['tasksFeatureCollection']>): Map.Root => ({
      ...state,
      tasksFeatureCollection: action.payload,
      loading: false,
    }),
  },

  [fetchTaskFiltersAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.TaskFilters>): Map.Root => ({
      ...state,
      taskFilters: action.payload,
    }),
  },

  [setSettingsAction.toString()]: {
    next: (state: Map.Root, action: Action<Partial<Map.Settings>>): Map.Root => {
      const nextState = { ...state, settings: { ...state.settings, ...action.payload } };
      const cachedState = getStorageItem<Map.MapState>(StorageKeys.MAP_CACHE) || ({} as Map.MapState);
      nextState.mapState = mergeMapState(cachedState, nextState, mapDefaultState);
      return nextState;
    },
  },

  [setMapLayersAction.toString()]: (state: Map.Root, action: Action<Map.StyleLayer[] | null>): Map.Root => ({
    ...state,
    mapLayers: action.payload,
  }),

  [setDataQualityWarningAction.toString()]: (
    state: Map.Root,
    action: Action<Map.DataQualityWarning | null>
  ): Map.Root => ({
    ...state,
    dataQualityWarning: action.payload,
  }),

  [fetchDataQualityWarningAction.toString()]: (
    state: Map.Root,
    action: Action<Map.DataQualityWarning | null>
  ): Map.Root => ({
    ...state,
    dataQualityWarning: action.payload,
  }),

  [fetchN1RouteAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.N1Route | null>): Map.Root => ({
      ...state,
      n1Route: action.payload,
      selectedN1Routes: action.payload?.routes?.filter(p => p.selected) ?? null,
    }),
  },

  [setN1RouteAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.N1Route | null>): Map.Root => ({
      ...state,
      n1Route: action.payload,
      selectedN1Routes: action.payload?.routes ?? null,
    }),
  },

  [setSelectedN1RoutesAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.N1RouteItem[] | null>): Map.Root => ({
      ...state,
      selectedN1Routes: action.payload,
    }),
  },

  [setSelectedN1RouteIdAction.toString()]: {
    next: (state: Map.Root, action: Action<number | null>): Map.Root => ({
      ...state,
      selectedN1RouteId: action.payload,
    }),
  },

  [fetchInvestmentScenariosAction.toString()]: {
    next: (state: Map.Root, action: Action<Type.SelectOption[]>): Map.Root => ({
      ...state,
      investmentScenarios: action.payload,
      selectedInvestmentScenario: action.payload[0],
    }),
  },

  [setSelectedInvestmentScenarioAction.toString()]: {
    next: (state: Map.Root, action: Action<Type.SelectOption | null>): Map.Root => ({
      ...state,
      selectedInvestmentScenario: action.payload,
    }),
  },

  [setMapDrawAssetFeaturesAction.toString()]: (
    state: Map.Root,
    action: Action<Map.Root['mapDrawAssetFeatures']>
  ): Map.Root => {
    setStorageItem({ [StorageKeys.MAP_DRAWN_FEATURES]: action.payload });
    return {
      ...state,
      mapDrawAssetFeatures: action.payload,
    };
  },

  [updateMapDrawAssetFeaturePropertiesAction.toString()]: (
    state: Map.Root,
    action: Action<{ id: string; properties: Map.MapDrawAssetFeatureProperties }>
  ): Map.Root => {
    const features =
      state.mapDrawAssetFeatures?.reduce(
        (acc: GeoJSON.Feature<GeoJSON.Geometry, Map.MapDrawAssetFeatureProperties>[], feature) => {
          if (feature.id === action.payload.id) {
            acc.push({ ...feature, properties: action.payload.properties });
          } else {
            acc.push(feature);
          }
          return acc;
        },
        []
      ) || null;
    setStorageItem({ [StorageKeys.MAP_DRAWN_FEATURES]: features });
    return {
      ...state,
      mapDrawAssetFeatures: features,
    };
  },
};

export {
  setMapLoading,
  fetchConfigAction,
  mapStateAction,
  fetchMaxLoadSummaryAction,
  fetchTasksFeatureCollection,
  fetchTaskFiltersAction,
  setSettingsAction,
  setConfigFetchedAction,
  setMapLayersAction,
  setDataQualityWarningAction,
  fetchNearestAssetLocationAction,
  fetchPopupAssetInfoAction,
  fetchN1RouteAction,
  setN1RouteAction,
  setSelectedN1RoutesAction,
  setSelectedN1RouteIdAction,
  setAddressSearchAction,
  fetchDataQualityWarningAction,
  fetchInvestmentScenariosAction,
  setSelectedInvestmentScenarioAction,
  setCableAnimationAction,
  setLabelsAction,
  fetchSingleLineDiagramAction,
  changeFilterVoltagesAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions<Map.Root>(ACTION_HANDLERS, initialState);
