import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { changeFilterVoltagesAction } from 'modules/map';
import {
  mapLoadingSelector,
  mapStateThemeSelector,
  mapVoltageCheckedFiltersSelector,
  mapVoltageFiltersListSelector,
  settingsSelector,
} from 'modules/map/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import LayerHint from 'components/Map/common/LayerHint';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import { ReactComponent as VoltageIcon } from 'components/Map/icons/icon • map panel section • voltage.svg';
import { IconVoltage } from '@utiligize/shared/resources';
import { MapThemes } from 'constants/index';

interface Props {
  singleDiagramEnabled: boolean;
  map: Exclude<Map.MapboxMap, null>;
}

const FilterVoltages: React.FC<Props> = ({ singleDiagramEnabled, map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const settings = useSelector(settingsSelector);
  const mapVoltageFiltersList = useSelector(mapVoltageFiltersListSelector);
  const mapVoltageCheckedFilters = useSelector(mapVoltageCheckedFiltersSelector);
  const theme = useSelector(mapStateThemeSelector);
  const mapLoading = useSelector(mapLoadingSelector);

  const activeFilters = useMemo(() => settings.themeActiveFilters?.[theme || '']?.filter_voltage_level, [theme]); // eslint-disable-line

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const voltage_level_id = Number(event.target.id);
    dispatch(changeFilterVoltagesAction(isChecked, voltage_level_id, map));
  };

  const renderItems = () => {
    if (!settings?.globalFilters?.filter_voltage) {
      return <StyledWarning>{getIntl('Voltage filter is not found')}</StyledWarning>;
    }

    return mapVoltageFiltersList.map(filter => (
      <StyledVoltageItem key={filter.id}>
        <ControlBox
          type="checkbox"
          id={String(filter.id)}
          name={filter.name ?? ''}
          checked={filter.isChecked && !filter.isDisabled}
          disabled={filter.isDisabled || (singleDiagramEnabled && mapLoading)}
          labelKey={filter.full_name ?? filter.name}
          onChange={onFilterChange}
          icon={<IconVoltage />}
          suffix={
            activeFilters?.includes(filter.id as number) && (
              <LayerHint text={getIntl('Filter that can affect selected theme')} type="dot" />
            )
          }
        />
      </StyledVoltageItem>
    ));
  };

  return (
    <CollapseGroup
      id="voltage"
      titleKey="Voltages"
      alwaysOpen={singleDiagramEnabled}
      icon={<VoltageIcon className="filter-group-icon" />}
      disabled={settings.isAssetGroupDisabled || theme === MapThemes.TASK}
      dataMarker="panel_voltage"
      description={
        <GroupHiddenDescription
          values={(mapVoltageCheckedFilters?.map(f => f.full_name ?? f.name) ?? []) as string[]}
          defaultValue="Nothing selected"
          showMoreItems
        />
      }
    >
      {renderItems()}
    </CollapseGroup>
  );
};

const StyledVoltageItem = styled.div`
  display: flex;
  align-items: center;
`;

const StyledWarning = styled.div`
  color: var(--map-text-color-inactive);
`;

export default FilterVoltages;
