import styled from 'styled-components';
import React, { useEffect, useCallback, useState } from 'react';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import Highcharts from 'highcharts';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { PanelGroup, Panel } from 'react-resizable-panels';
import { useDebouncedCallback } from 'use-debounce';
import { fetchAssetAction } from 'modules/assets';
import { assetMetaSelector } from 'modules/assets/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { isMapConfigFetchedSelector } from 'modules/map/selectors';
import { Alert, Button, Spinner } from 'components/_common';
import Legend from 'components/Map/Legend';
import TopPanelTabs from './TopPanelTabs';
import BottomPanelTabs from './BottomPanelTabs';
import ResizeHandle from '../ResizeHandle';
import { Routes } from 'constants/index';
import { IconChevronLeft, IconVoltage } from '@utiligize/shared/resources';

const UnifiedAssetPanel: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const isMapConfigFetched = useSelector(isMapConfigFetchedSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const assetMeta = useSelector(assetMetaSelector);

  // UAV, must reset asset meta immediately on uuid change event
  const [memo, setMemo] = useState<string | null>(null);
  const isAssetMetaFetched = uuid === memo;

  const assetCategoryCode = assetMeta?.category?.code || assetMeta?.cnaimCategory?.code;
  const isCustomerAssetType = assetMeta?.asset?.map_name === 'Customer';

  useEffect(() => {
    if (!uuid || !versionId) return;
    dispatch(fetchAssetAction({ uuid, versionId })).finally(() => setMemo(uuid));
  }, [uuid, versionId, dispatch]);

  const debouncedOnResize = useDebouncedCallback(() => {
    // Automatic high charts reflow trigger
    Highcharts.charts.forEach(c => c?.reflow());
  }, 1000);

  useEffect(() => {
    return debouncedOnResize.cancel;
  }, [debouncedOnResize.cancel]);

  const handleBackButtonClick = useCallback(() => {
    dispatch(push(generatePath(Routes.Map)));
  }, [dispatch]);

  const renderPanels = () => (
    <StyledPanelGroup autoSaveId="vertical-map-panel-group" direction="vertical">
      <StyledPanel id="top" order={1} defaultSize={50} minSize={25} maxSize={75}>
        <StyledTopPanelBar>
          <Button
            icon={<IconChevronLeft />}
            labelKey="Back to panel"
            variant="primary"
            onClick={handleBackButtonClick}
            className="mr-2"
          />
          <StyledAssetName>
            {assetMeta?.asset && (
              <>
                {assetMeta.asset?.name} • {getIntl(assetMeta.asset?.map_name)} • {getIntl(assetMeta.asset?.voltage)}
                <IconVoltage />
              </>
            )}
          </StyledAssetName>
        </StyledTopPanelBar>
        {assetMeta ? (
          <TopPanelTabs
            assetMeta={assetMeta}
            uuid={uuid!}
            assetCategoryCode={assetCategoryCode}
            isCustomerAssetType={isCustomerAssetType}
          />
        ) : isAssetMetaFetched ? (
          <Alert className="my-auto mx-2 align-self-center" variant="danger">
            {getIntl('Asset information by provided asset code not found')}
          </Alert>
        ) : (
          <Spinner isInFullHeightContainer />
        )}
      </StyledPanel>
      <ResizeHandle id="horizontal-resize-handle" direction="row-resize" />
      <StyledPanel id="bottom" order={2} defaultSize={50} minSize={25} maxSize={75} onResize={debouncedOnResize}>
        <BottomPanelTabs
          uuid={uuid!}
          cnaim_id={assetMeta?.cnaimCategory.id ?? null}
          isAssetMetaFetched={isAssetMetaFetched}
          isCustomerAssetType={isCustomerAssetType}
        />
      </StyledPanel>
    </StyledPanelGroup>
  );

  return (
    <StyledRootPanel id="right" order={2} defaultSize={25} minSize={20} maxSize={75} onResize={debouncedOnResize}>
      <ResizeHandle id="vertical-resize-handle" />
      <StyledPanelContent>
        {!isMapConfigFetched ? <Spinner isInFullHeightContainer /> : !uuid ? <Legend /> : renderPanels()}
      </StyledPanelContent>
    </StyledRootPanel>
  );
};

const StyledRootPanel = styled(Panel)`
  display: flex;
  overflow: visible !important;
`;

const StyledPanel = styled(Panel)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledPanelContent = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => props.theme.colors.grey25};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 450px;
  position: relative;
`;

const StyledPanelGroup = styled(PanelGroup)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTopPanelBar = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background: ${props => props.theme.colors.white};
  border-bottom: solid 1px ${props => props.theme.colors.grey100};

  > button {
    padding-left: 5px;
    > span.label {
      margin-left: 1px;
    }
  }
`;

const StyledAssetName = styled.div`
  color: ${props => props.theme.colors.grey900};
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;

  > svg {
    margin-left: 5px;
    position: relative;
    top: -1px;

    path {
      fill: ${props => props.theme.colors.grey900};
    }
  }
`;

export default UnifiedAssetPanel;
