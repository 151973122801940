import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { missingAgeItemsSelector, missingAgeItemsCountSelector } from 'modules/dataQuality/selectors';
import { fetchMissingAgeAction } from 'modules/dataQuality';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props {
  cnaim_id: Layouts.Filters['cnaim_id'];
}

const TableMissingAge: React.FC<Props> = ({ cnaim_id }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(missingAgeItemsSelector);
  const count = useSelector(missingAgeItemsCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchMissingAgeAction(params)).then(
        (action: Shared.ReduxAction<{ missingAgeItems: DataQuality.Root['missingAgeItems'] }>) =>
          action.payload?.missingAgeItems || []
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.DATA_QUALITY_MISSING_AGE}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 292px)"
      triggerTableUpdateDeps={[portfolioId, versionId]}
      waitForDependencies={!cnaim_id}
    >
      {items?.map((item: DataQuality.MissingAge) => (
        <tr key={item.id}>
          <td>{item.customer_asset_name}</td>
          <td>{item.type || '-'}</td>
          <td>{item.manufacturer || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableMissingAge;
