import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapVoltageCheckedFiltersSelector } from 'modules/map/selectors';
import DiagramDefs from './DiagramDefs';

const DiagramLegend: React.FC = () => {
  const { getIntl } = useLocale();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const mapVoltageCheckedFilters = useSelector(mapVoltageCheckedFiltersSelector);

  const legendData = {
    nodes:
      mapVoltageCheckedFilters?.map(filter => ({
        voltage_id: filter.id,
        voltage_level_text: `${getIntl(filter.full_name || '')} ${getIntl('Voltage').toLowerCase()}`,
      })) || [],
    links: [
      {
        asset_icon_name: 'two_circles',
        cnaim_text: getIntl('Transformers'),
      },
      {
        in_operation: true,
        normal_state: 'I',
        asset_icon_name: 'switch',
        cnaim_text: getIntl('Switch (closed)'),
      },
      {
        in_operation: true,
        normal_state: '',
        asset_icon_name: 'switch',
        cnaim_text: getIntl('Switch (opened)'),
      },
    ],
  };

  useEffect(() => {
    if (!svgRef.current) return;

    // Set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, left: 30, bottom: 10 };
    const width = 195;
    const height = (legendData.nodes.length + legendData.links.length) * 19.5;

    // Append the svg object to the chart div
    const svg = d3.select(svgRef.current);
    svg.attr('width', width).attr('height', height);

    // Append groups for nodes and links
    const linksGroup = svg.select('.links-group').attr('transform', `translate(${margin.left},${margin.top})`);
    const nodesGroup = svg.select('.nodes-group').attr('transform', `translate(${margin.left}, ${margin.top})`);

    const nodes = nodesGroup
      .selectAll('g')
      .data(legendData.nodes)
      .join('g')
      .attr('class', () => 'node');

    nodes
      .selectAll('circle')
      .data(legendData.nodes)
      .join('circle')
      .attr('class', d => `voltages-used-${d.voltage_id}`)
      .attr('cx', 2)
      .attr('cy', (d, i) => i * 18)
      .attr('r', 6);
    nodes
      .selectAll('text')
      .data(legendData.nodes)
      .join('text')
      .attr('class', 'node-label legend')
      .text(d => d.voltage_level_text)
      .attr('alignment-baseline', 'middle')
      .attr('y', (d, i) => i * 18)
      .attr('x', 50);

    const links = linksGroup
      .selectAll('g')
      .data(legendData.nodes)
      .join('g')
      .attr('class', () => 'link legend');

    links
      .selectAll('text')
      .data(legendData.links)
      .join('text')
      .attr('class', 'node-label legend')
      .text((d: any) => d.cnaim_text)
      .attr('alignment-baseline', 'middle')
      .attr('y', (d, i) => Object.keys(legendData.nodes).length * 18 + 10 + i * 18)
      .attr('x', 50);

    links
      .selectAll('path')
      .data(legendData.links)
      .join('path')
      .style('stroke', '#898984')
      .attr('d', (d: any, i: number) => {
        const x1 = -25;
        const y1 = Object.keys(legendData.nodes).length * 18 + 10 + i * 18;
        const x2 = 30;
        const y2 = Object.keys(legendData.nodes).length * 18 + 10 + i * 18;

        return `M ${x1} ${y1} L ${(x1 + x2) / 2} ${(y1 + y2) / 2} M ${(x1 + x2) / 2} ${(y1 + y2) / 2} L ${x2} ${y2}`;
      })
      .attr('marker-mid', (d: any) => {
        let icon = '';

        switch (d.asset_icon_name) {
          case 'switch':
            if (d.in_operation) {
              if (d.normal_state === 'I') {
                icon = 'url(#switch-closed)';
              } else {
                icon = 'url(#switch-open)';
              }
            }
            break;
          case 'two_circles':
            icon = 'url(#two_circles)';
            break;
          default:
            icon = '';
        }

        return icon;
      });
  }, [legendData.links, legendData.nodes]);

  return (
    <div className="single-line-legend-wrapper">
      <div className="single-line-legend">
        <svg ref={svgRef}>
          <DiagramDefs />
          <g className="links-group" />
          <g className="nodes-group" />
        </svg>
      </div>
    </div>
  );
};

export default DiagramLegend;
