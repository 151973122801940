import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapStateAction } from 'modules/map';
import {
  createMapStateSelectorFactory,
  enabledLayersListSelector,
  mapStateLayerFiltersSelector,
  settingsSelector,
  showLabelsSelector,
} from 'modules/map/selectors';
import { filterLayer, setMapSourceField, getAssetLayerFilters, getDefaultFilterIds } from 'utils/map';

interface Props {
  map: Map.MapboxMap | null;
  styleLayers: Map.StyleLayer[];
}

const SetupFilters: React.FC<Props> = ({ map, styleLayers }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const showLabels = useSelector(showLabelsSelector);
  const layerFilters = useSelector(mapStateLayerFiltersSelector);
  const globalFilters = useSelector(createMapStateSelectorFactory('globalFilters'));
  const enabledLayersList = useSelector(enabledLayersListSelector);
  const prevEnabledList = useRef<string[]>([]);

  useEffect(() => {
    const filteredLayers = styleLayers.filter(l => !!l.legend?.filters);
    const filters = filteredLayers.reduce(
      (acc, el) => {
        const id = el.legend!.id;
        const isVisible = enabledLayersList.some(i => i.startsWith(id));
        if (
          !layerFilters[id] ||
          (layerFilters[id]?.initList && layerFilters[id]?.initList.length !== el.legend?.filters?.list.length)
        ) {
          const filterIds = getDefaultFilterIds(el.legend?.filters?.list || []);
          acc[id] = {
            ...el.legend?.filters,
            initList: el.legend?.filters?.list,
            list: isVisible ? (el.legend?.id === 'other__heatmaps' ? [filterIds[0]] : filterIds) : [],
          } as Map.LayerFilter;
        }
        return acc;
      },
      {} as Record<string, Map.LayerFilter>
    );
    dispatch(
      mapStateAction({
        layerFilters: { ...layerFilters, ...filters },
      })
    );
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!map) return;
    const otherLayers = [...settings.otherLayers!, ...settings.cnaimLayers!].filter(i => !/(highlight)/.test(i));
    for (const layerId of Object.keys(layerFilters)) {
      if (layerId.includes('asset__')) continue;
      const layer = styleLayers.find(k => k.legend?.id === layerId);
      if (layer?.legend?.filters) {
        const filter = layerFilters[layerId]!;
        const layers = otherLayers.filter(i => i.includes(layerId));
        if (layerId === 'other__heatmaps') {
          setMapSourceField(map, layers, { heatmap_section: String(filter.list[0]) });
        } else {
          layers.forEach(i => filterLayer(map, i, ['in', filter.property, ...filter.list]));
        }
      }
    }
  }, [map, layerFilters, styleLayers]); // eslint-disable-line

  // filter assets and all themes by voltage and stations for multitenant map
  useEffect(() => {
    if (!map) return;
    const other = [
      ...settings.dataQualityLayers!,
      ...settings.maxLoadLayers!,
      ...settings.maxLoadIconsLayers!,
      ...settings.consumptionLayers!,
      ...settings.lossesLayers!,
      ...settings.taskLayers!,
      ...settings.n1Layers!,
      ...settings.n1MaxLoadLayers!,
      ...settings.replacementsLayers!,
    ].filter(i => !i.endsWith('highlight'));
    for (const layerId of Object.keys(layerFilters)) {
      const layer = styleLayers.find(k => k.legend?.id === layerId);
      if (layer?.legend?.filters) {
        const filter = layerFilters[layerId]!;
        const layers = settings.assetLayers!.filter(l => l.startsWith(layerId) && !l.endsWith('highlight'));
        layers.forEach(i => {
          const assetAndThemeLayers = [i, ...other.filter(o => o.includes(i))];
          const stations = globalFilters.filter_primary_substations;
          const stationFilter = stations?.property
            ? stations?.list.length
              ? stations?.initList?.length !== stations?.list.length
                ? [
                    'any',
                    ['match', ['get', stations?.property + '_from'], [...(stations?.list ?? [])], true, false],
                    ['match', ['get', stations?.property + '_to'], [...(stations?.list ?? [])], true, false],
                  ]
                : true
              : false
            : true;
          const voltageFilter =
            filter.list?.length && filter.initList?.length !== filter.list?.length
              ? ['match', ['get', filter.property], [...filter.list], true, false]
              : true;
          const f = (() => {
            if (!stationFilter || !voltageFilter) return false;
            if (stationFilter === true && voltageFilter === true) return true;
            if (Array.isArray(stationFilter) && Array.isArray(voltageFilter)) {
              return ['all', ['to-boolean', stationFilter], ['to-boolean', voltageFilter]];
            }
            if (Array.isArray(stationFilter)) return stationFilter;
            if (Array.isArray(voltageFilter)) return voltageFilter;
            return true;
          })();
          assetAndThemeLayers
            .filter(i => !i.endsWith('cluster'))
            .forEach(i => {
              const { voltageFilter, layerFilter, textFilter } = getAssetLayerFilters(i);
              filterLayer(map, i, ['all', voltageFilter, layerFilter, f, textFilter]);
            });
        });
      }
    }
  }, [map, layerFilters, globalFilters, styleLayers]); // eslint-disable-line

  useEffect(() => {
    if (!map) return;
    settings.textLayers?.forEach((layer: string) => {
      const { voltageFilter, layerFilter, assetFilter } = getAssetLayerFilters(layer);
      filterLayer(map, layer, ['all', voltageFilter, layerFilter, assetFilter, showLabels]);
    });
  }, [map, showLabels, settings.textLayers]);

  useEffect(() => {
    prevEnabledList.current = enabledLayersList;
  }, [enabledLayersList]);

  return null;
};

export default React.memo(SetupFilters);
