import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import { ReactComponent as GroupIcon } from 'components/Map/icons/icon • map panel section • added objects.svg';
import { mapSimulationOptionSelector, simulationOptionsSelector } from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';

const FilterSimulationVersion: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const simulations = useSelector(simulationOptionsSelector);
  const mapSimulationOption = useSelector(mapSimulationOptionSelector);

  const setSelectedSimulation = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = event.target.getAttribute('id');
      dispatch(setLayoutAction({ mapSimulationId: id ? Number(id) : null }));
    },
    [dispatch]
  );

  return (
    <StyledCollapseGroup
      id="simulations"
      titleKey="Assets and simulation version"
      icon={<GroupIcon className="filter-group-icon" />}
      description={mapSimulationOption?.label}
      disabled={!simulations?.length}
    >
      <OverlayScrollbarsComponent style={{ maxHeight: '140px' }}>
        <div className="scroll-inner">
          {simulations?.map(s => (
            <div className="item" key={s.value}>
              <ControlBox
                type="radio"
                id={String(s.value)}
                checked={mapSimulationOption?.value === s.value}
                labelKey={s.label}
                onChange={setSelectedSimulation}
              />
            </div>
          ))}
        </div>
      </OverlayScrollbarsComponent>
    </StyledCollapseGroup>
  );
};

const StyledCollapseGroup = styled(CollapseGroup)`
  .os-host {
    margin: 0 -18px;
  }

  .scroll-inner {
    padding: 0 18px;
    position: relative;
  }
`;

export default FilterSimulationVersion;
