import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { usePaginationHandlers } from 'hooks';
import { scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { Spinner } from 'components/_common';
import { PaginationType, AssetLifeAPI } from 'constants/index';

const ChartVoltageDrop: React.FC = () => {
  const type = PaginationType.NETWORK_LOADING_VOLTAGE_DROP;
  const { filters } = usePaginationHandlers({ type });
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const src = Boolean(
    simulationId && versionId && scenarioId && filters?.year && filters?.percentile && filters?.primarySubstationsId
  )
    ? `plots/voltage_drop_plot?${queryString.stringify({
        simulation_id: simulationId,
        version_id: versionId,
        scenario_id: scenarioId,
        year: filters?.year,
        percentile: filters?.percentile,
        primary_substation_id: filters?.primarySubstationsId,
        min_or_max: filters?.min_or_max,
        investment: filters?.investment,
        flexible: filters?.flex,
      })}`
    : '';

  const [base64, setBase64] = useState(undefined);

  useEffect(() => {
    setBase64(undefined);
    if (!src) return;
    AssetLifeAPI.get(src)
      .then((res: any) => setBase64(res.data.image))
      .catch(console.error);
  }, [src]);

  return (
    <StyledContainer>
      {base64 ? (
        <img src={`data:image/png;base64, ${base64}`} alt="Plot voltage drop" />
      ) : (
        <Spinner isInFullHeightContainer />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 70vh;
  display: flex;
  justify-content: center;

  > img {
    height: 100%;
  }
`;

export default ChartVoltageDrop;
