import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useLocale, useReadableTable } from 'hooks';
import {
  rootSelector,
  bottomTabsChartsNextYearValueSelector,
  settingsSelector,
  mapStateLayerFiltersSelector,
} from 'modules/map/selectors';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import {
  featureTogglesSelector,
  isMapConnectionsEnabledSelector,
  isExtendedGridEnabledSelector,
} from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { fetchPopupAssetInfoAction, fetchN1RouteAction, mapStateAction } from 'modules/map';
import { isLayerExist, setFeatureState, removeFeatureState, showLayer } from 'utils/map';
import { getAssetLayerFilters, layerListToRecord, filterLayer, getPopupCoords, formatPopupProps } from 'utils/map';
import { Routes, StorageKeys } from 'constants/index';
import { MapPopup } from 'components/Map/common/Popup';
import SearchPopup, { SearchPopupProps } from './SearchPopup';
import { _pick, _uniqBy } from '@utiligize/shared/utils';

interface Props {
  map: Map.MapboxMap;
  styleLayers: Map.StyleLayer[];
}

const SetupSearch: React.FC<Props> = ({ map, styleLayers }) => {
  const isDebugEnabled = localStorage.getItem('DEBUG_ENABLED');
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const { formatProp, formatValue } = useReadableTable();

  const settings = useSelector(settingsSelector);
  const bottomTabsChartsNextYearValue = useSelector(bottomTabsChartsNextYearValueSelector);
  const uuid = useSelector(mapPanelSelectedAssetUUIDSelector);
  const layerFilters = useSelector(mapStateLayerFiltersSelector);
  const mapRoot = useSelector(rootSelector);
  const featureToggles = useSelector(featureTogglesSelector);
  const isExtendedGridEnabled = useSelector(isExtendedGridEnabledSelector);
  const isMapConnectionsEnabled = useSelector(isMapConnectionsEnabledSelector);

  const popup = useMemo(
    () => new mapboxgl.Popup({ closeButton: false, closeOnClick: true, className: 'utiligize-map-popup hoverable' }),
    []
  );
  const clickContainer = useMemo(() => document.createElement('div'), []);
  const clickRoot = useMemo(() => createRoot(clickContainer), [clickContainer]);
  const selectedAssetRef = useRef<Map.SearchPopupItem | null>(null);

  const {
    location: { state },
  } = useHistory<{ keepMapState: boolean; keepMapTheme: boolean } | null>();

  const handleAssetHover = useCallback(
    ({ id, source, table }: Map.SearchPopupItem) => {
      const prev = map.getFeatureState({ id, source, sourceLayer: table });
      setFeatureState(map, { id, source, sourceLayer: table }, { ...prev, highlight: true });
    },
    [map]
  );

  const handleAssetSelect = useCallback(
    ({ id, source, table }: Map.SearchPopupItem) => {
      const prev = map.getFeatureState({ id, source, sourceLayer: table });
      setFeatureState(map, { id, source, sourceLayer: table }, { ...prev, select: true });
    },
    [map]
  );

  const handleAssetLeave = useCallback(
    ({ id, source, table }: Map.SearchPopupItem) => {
      const prev = map.getFeatureState({ id, source, sourceLayer: table });
      setFeatureState(map, { id, source, sourceLayer: table }, { ...prev, highlight: false });
    },
    [map]
  );

  const handleAssetReset = useCallback(
    ({ source, table }: Map.SearchPopupItem): void => removeFeatureState(map, { source, sourceLayer: table }),
    [map]
  );

  const openUnifiedAssetPanel = useCallback(
    (asset: Map.SearchPopupItem) => {
      const { assetId } = asset;
      const prevSelected = selectedAssetRef.current;
      if (bottomTabsChartsNextYearValue) {
        dispatch(setLayoutAction({ selectedChartYear: bottomTabsChartsNextYearValue }));
      }
      dispatch(push(generatePath(Routes.Map, { uuid: assetId }), { keepMapState: true }));
      dispatch(setLayoutAction({ mapPanelEnabled: true }));
      if (prevSelected) handleAssetReset(prevSelected);
      handleAssetSelect(asset);
      selectedAssetRef.current = asset;
    },
    [dispatch, handleAssetSelect, handleAssetReset, bottomTabsChartsNextYearValue]
  );

  const handleN1RouteClick = useCallback(
    (asset: Map.SearchPopupItem) => {
      dispatch(fetchN1RouteAction({ asset_uuid: asset.assetId }));
      dispatch(push(generatePath(Routes.Map)));
    },
    [dispatch]
  );

  const resetHighlightItems = useCallback(() => {
    popup.remove();
    settings.highlightLayers?.forEach(i => {
      const { voltageFilter } = getAssetLayerFilters(i);
      filterLayer(map, i, ['all', voltageFilter, false]);
    });
  }, [map, settings.highlightLayers, popup]);

  const setHighlightItems = useCallback(
    (itemsHash: Record<string, number[]>, feederFeaturesIds: number[] = []) => {
      if (!itemsHash) return;
      Object.keys(itemsHash).forEach(layer => {
        const highlightLayer = layer.replace(/(_voltage|_column).*/gi, '');
        const ids = itemsHash[layer].filter(Boolean);
        const highlightFilters = ids.map(id => ['==', ['id'], id]);
        settings
          .highlightLayers!.filter(i => i.includes(highlightLayer))
          .forEach(layer => {
            // 1. cables under the same feeder must be highlighted differently
            if (feederFeaturesIds?.length && map.getLayer(layer)?.type === 'line') {
              const lineWidth = map.getPaintProperty(layer, 'line-width');
              const defaultValue = lineWidth?.[lineWidth?.length - 1] || lineWidth || 20;
              const matchValue = [
                'match',
                ['id'],
                ...feederFeaturesIds.map(id => [id, defaultValue / 2]).flat(),
                defaultValue,
              ];
              // null value will reset line width the default value
              map.setPaintProperty(layer, 'line-width', matchValue);
            }
            // 2. highlight cables based on provided itemsHash ids
            const { voltageFilter } = getAssetLayerFilters(layer);
            filterLayer(map, layer, ['all', voltageFilter, ['any', ...highlightFilters]]);
          });
      });
    },
    [map, settings.highlightLayers]
  );

  useEffect(() => {
    // keepMapState. for uav, search input we need to update map filters to be sure the searched asset visible on the map
    // but we don't need to do the same for direct map clicks
    if (state?.keepMapState) return;
    // back to panel button click handler
    if (!uuid) return resetHighlightItems();
    dispatch(fetchPopupAssetInfoAction(uuid)).then((action: Shared.ReduxAction<Map.FetchFindAssetData>) => {
      if (!action.payload) return resetHighlightItems();
      const { id, layer_name, lat, lng } = action.payload;
      setHighlightItems({ [layer_name]: [id] });

      const styleLayer = styleLayers.find(k => k.legend?.id === layer_name);
      if (!styleLayer) return;
      const layersToShow = [...settings.assetLayers!, ...settings.otherLayers!].filter(i => i.startsWith(layer_name));

      // TableDetailedInvestments has deep link and we need to keep preselected theme
      if (!state?.keepMapTheme) {
        // we have to disable theme since endpoint supports only default map assets state
        const nextMapState = { theme: null, themeGroup: null } as Partial<Map.MapState>;
        const substations = 'filter_primary_substations';
        const substationsInitState = settings.globalFilters?.[substations];
        const initFilterList = layerFilters[layer_name]?.initList?.map(i => i.id);

        nextMapState.globalFilters = {
          [substations]: { list: substationsInitState?.list.map(i => i.id) ?? [] } as Map.LayerFilter,
        };
        if (initFilterList) {
          nextMapState.layerFilters = { [layer_name]: { list: initFilterList } } as Record<string, Map.LayerFilter>;
        }
        nextMapState.enabledLayers = layerListToRecord(layersToShow, true);

        dispatch(mapStateAction(nextMapState));
        layersToShow.forEach(i => showLayer(map, i));
      }

      map.flyTo({ center: [lng, lat], essential: true, zoom: Math.max(map.getZoom(), 18) });

      // wait for flyTo animation ends
      map.once('moveend', () => {
        // wait for tiles download ends and show popup
        map.once('idle', () => {
          const layersForPopup = settings.infoLayers!.filter(
            i => layersToShow.some(l => i.includes(l)) && isLayerExist(map, i)
          );
          // find all feature on the map viewport
          const features = map.queryRenderedFeatures(undefined, { layers: layersForPopup });
          // search for target feature based on API response
          const searchedFeature = features.find(i => i.id === id);
          if (!searchedFeature) return;
          // calculate popup coordinates
          const coords = getPopupCoords(searchedFeature, styleLayer, [lng, lat]);
          if (!coords) return;
          // format popup data
          const popupData = formatPopupProps(searchedFeature, styleLayer, mapRoot);
          // render popup
          clickRoot.render(
            <MapPopup data={popupData} getIntl={getIntl} formatProp={formatProp} formatValue={formatValue} />
          );
          // add popup to the map
          popup
            .setLngLat(coords as mapboxgl.LngLatLike)
            .setDOMContent(clickContainer)
            .addTo(map);
        });
      });
    });
  }, [dispatch, uuid]); // eslint-disable-line

  useEffect(() => {
    if (isExtendedGridEnabled || isMapConnectionsEnabled) return;
    const onMapClick = (e: mapboxgl.MapMouseEvent) => {
      // 1. find clicked rendered unique features with tolerance
      const point = e.point;
      const zoom = Math.round(map.getZoom()); // zoom
      const tolerance = zoom >= 16 ? zoom * 0.5 : zoom >= 10 ? 3 : 1; // tolerance
      // southwest and northeast points describing a bounding box
      const bbox = [
        [point.x - tolerance, point.y - tolerance],
        [point.x + tolerance, point.y + tolerance],
      ] as any;
      const infoLayers = settings.infoLayers!.flatMap(i => [i, i + '_column', i + '_column_2']);
      const layers = infoLayers.filter(i => isLayerExist(map, i));
      const uniqueFeatures = _uniqBy(
        map.queryRenderedFeatures(bbox, { layers }).filter(f => f.properties?.asset_id),
        'id'
      );
      if (!uniqueFeatures.length) return;

      // 2. find unique feeder features for 'asset__cables' layers
      const feederFeatures = (() => {
        const { id, source, properties } = uniqueFeatures[0];
        if (isDebugEnabled) console.info('target click properties:', properties, source);
        if (!featureToggles[StorageKeys.FEATURE_TOGGLE_FEEDERS_ANIMATION]) return [];
        if (!source.includes('asset__cables') || (!properties?.lv_feeder_id && !properties?.mv_feeder_id)) return [];
        const { cnaim_id, lv_feeder_id, mv_feeder_id } = properties;
        return _uniqBy(
          map.queryRenderedFeatures(undefined, { layers: layers.filter(l => l.includes(source)) }).filter(f => {
            if (f.id === id) return false;
            if (lv_feeder_id) return f.properties?.lv_feeder_id === lv_feeder_id;
            if (mv_feeder_id) {
              return f.properties?.mv_feeder_id === mv_feeder_id && f.properties?.cnaim_id === cnaim_id;
            }
            return false;
          }),
          'id'
        );
      })();

      if (isDebugEnabled) {
        console.info(
          'feederFeatures',
          feederFeatures.map(f => f.properties)
        );
      }

      // 3. highlight features
      const highlightItemsHash = [...uniqueFeatures, ...feederFeatures].reduce(
        (acc, f) => {
          if (!acc[f.source]) acc[f.source] = [];
          acc[f.source].push(f.id as number);
          return acc;
        },
        {} as Record<string, number[]>
      );
      setHighlightItems(
        highlightItemsHash,
        feederFeatures.map(f => Number(f.id))
      );

      // 4. transform features to items
      const items = uniqueFeatures
        .map(f => {
          const layer = f.layer.id;
          const source = f.source;
          const table = f.sourceLayer;
          const type = f.layer.type;
          const id = f.id as number;
          const voltage = f.properties?.voltage_level_id;
          const voltageName = f.properties?.voltage_level;
          const hasN1Route = source.startsWith('n_1__') && !layer.endsWith('additional') && f.properties?.in_theme;
          const titleKey = Object.keys(settings.layerTitle!).find(k => layer.startsWith(k));
          const key = settings.layerTitle![titleKey!];
          const filterKey = Object.keys(layerFilters).find(k => layer.includes(k));
          const filter = layerFilters[filterKey!]?.initList.find(f => f.id === voltage);
          const name = f.properties?.name;
          const installationNumber = f.properties?.installation_number;
          const assetCode = f.properties?.asset_code;
          const assetId = f.properties?.asset_id;
          const icon = (f.layer.layout as any)?.['icon-image']?.toString();
          const additional = _pick(filter ?? {}, ['color', 'icon']);
          return {
            layer,
            key,
            id,
            name,
            installationNumber,
            type,
            assetId,
            assetCode,
            voltage,
            voltageName,
            table,
            source,
            hasN1Route,
            icon,
            ...additional,
          };
        })
        .sort((a, b) => {
          const byLayer = (layer: string) => {
            if (/__transformer/.test(layer)) return 5;
            if (/__cabinet/.test(layer)) return 4;
            if (/__cable/.test(layer)) return 3;
            if (/__load_switch/.test(layer)) return 2;
            if (/__switch/.test(layer)) return 1;
            return 0;
          };
          const byGroup = (layer: string) => {
            if (/__primary/.test(layer)) return 2;
            if (/__secondary/.test(layer)) return 1;
            return 0;
          };
          const l = byLayer(b.layer) - byLayer(a.layer);
          const g = byGroup(b.layer) - byGroup(a.layer);
          const v = b.voltage - a.voltage;
          return l || g || v;
        })
        .reduce(
          (acc, { key, layer, ...rest }) => {
            if (!acc[key]) acc[key] = [];
            acc[key].push(rest);
            return acc;
          },
          {} as SearchPopupProps['items']
        );

      // 5. open unified asset panel
      const item = items[Object.keys(items)[0]][0];
      if (item) openUnifiedAssetPanel(item);

      // 6. open popup
      if (uniqueFeatures.length === 1) return;
      clickRoot.render(
        <SearchPopup
          items={items}
          openUnifiedAssetPanel={openUnifiedAssetPanel}
          onN1RouteClick={handleN1RouteClick}
          onMouseEnter={handleAssetHover}
          onMouseLeave={handleAssetLeave}
          getIntl={getIntl}
        />
      );
      popup.setLngLat(e.lngLat).setDOMContent(clickContainer).addTo(map);
    };

    map.on('click', onMapClick);
    return () => {
      map.off('click', onMapClick);
    };
  }, [
    map,
    openUnifiedAssetPanel,
    layerFilters,
    settings.infoLayers,
    settings.layerTitle,
    handleAssetHover,
    handleAssetLeave,
    handleN1RouteClick,
    getIntl,
    isDebugEnabled,
    setHighlightItems,
    clickRoot,
    clickContainer,
    popup,
    featureToggles,
    isExtendedGridEnabled,
    isMapConnectionsEnabled,
  ]);

  return <StyledPopup />;
};

const StyledPopup = createGlobalStyle`
	.mapboxgl-popup.hoverable {
		&, & * {
			pointer-events: auto;
		}
	}
`;

export default SetupSearch;
