import axios from 'axios';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { PaginationType } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchHistoryAction: any = createAction(
  'history/FETCH_ALL',
  async (
    {
      uuid,
      assetCode,
      portfolioId,
      versionId,
      skipPagination,
      queryInput,
    }: {
      uuid: string;
      assetCode: string;
      portfolioId: Layouts.Root['portfolioId'];
      versionId: number;
      skipPagination: boolean;
      queryInput: string;
    } = {} as any
  ) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<History.Root, 'count' | 'rows'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.HISTORY)(state);
      return axios
        .get('api/admin/v2/secure/histories', {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipPagination ? queryInput || null : query,
            lang: appLangSelector(state).toLowerCase(),
            filters: (uuid && { uuid }) || (assetCode && { assetCode }) || filters,
            portfolioId,
            versionId,
          },
        })
        .then(res => ({ count: res.data.total, rows: res.data.items }));
    }
);
